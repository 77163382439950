import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Login from "../Auth/Login";
import EmailVerify from "../Auth/EmailVerify";
import NewPassword from "../Auth/NewPassword";
import SignUp from "../Auth/SignUp";
export default function Footer() {
  const [fixLogin, setFixLogin] = useState(false);
  const toggleFixLogin = () => {
    setFixLogin(!fixLogin);
  };
  const [fixSignUp, setFixSignUp] = useState(false);
  const fixSignUpToggle = (e) => {
    setFixSignUp(e);
  };
  const [fixVerifyEmail, setFixVerifyEmail] = useState(false);
  const toggleFixEmail = () => {
    setFixVerifyEmail(!fixVerifyEmail);
  };
  const [fixNewPassword, setFixNewPassword] = useState(false);
  const toggleFixNewPassword = () => {
    setFixNewPassword(!fixVerifyEmail);
  };

  const token = Cookies.get("token");
  const navigate = useNavigate();

  return (
    <>
      {/* <!-- FOOTER START --> */}
      <footer
        className="footer-dark"
        style={{
          backgroundImage: "url(images/f-bg.jpg)",
        }}
      >
        <div className="container">
          {/* <!-- FOOTER BLOCKES START --> */}
          <div className="footer-top border-0 pt-0">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="widget widget_about">
                  <div className="logo-footer clearfix">
                    <Link to={"/"}>
                      <img src="images/logo.png" alt="" />
                    </Link>
                  </div>
                  <p>
                    Many desktop publishing packages and web page editors now.
                  </p>
                </div>
              </div>

              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="widget widget_services ftr-list-center">
                      <ul>
                        <li>
                          {/* <a
                            target="blank"
                            href="mailto:hello@shopspot.app" > */}
                          <p>
                            <span>Email -</span> hello@shopspot.app
                          </p>
                          {/* </a> */}
                        </li>
                        <li>
                          <a href={`tel:+973 -  37721234`}>
                            <p>
                              <span>Call -</span> +973 - 37721234
                            </p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="widget widget_services ftr-list-center">
                      <ul>
                        <li>
                          <a
                            onClick={() => {
                              token ? navigate("/profile") : toggleFixLogin();
                            }}
                          >
                            Profile
                          </a>
                        </li>

                        <li>
                          <a>Contact</a>
                        </li>

                        <li>
                          <a
                            onClick={() => {
                              token ? navigate("/profile") : toggleFixLogin();
                            }}
                          >
                            FAQs
                          </a>
                        </li>

                        <li>
                          <a>About us</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="widget widget_services ftr-list-center">
                      <ul>
                        <li>
                          <Link to={"/"}> Home </Link>
                        </li>
                        <li>
                          <Link to={"/business"}> Business </Link>
                        </li>
                        {/* <li>
                          <Link to={"/freelancedetail"}>Freelance</Link>
                        </li> */}
                        <li>
                          <Link to={"/careerlist"}>career</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- FOOTER COPYRIGHT --> */}
          <div className="footer-bottom">
            <div className="footer-bottom-info">
              <div className="footer-copy-right">
                <span className="copyrights-text">
                  Copyright © 2023 by Shopspot.
                </span>
              </div>
              <ul className="social-icons">
                <li>
                  <a
                    href="https://m.facebook.com/shopspot.appp"
                    target="blank"
                    className="fab fa-facebook-f"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/shopspot_app"
                    target="blank"
                    className="fab fa-twitter"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/shopspot.app/?igshid=YWJhMjlhZTc%3D"
                    target="blank"
                    className="fab fa-instagram"
                  ></a>
                </li>
                <li>
                  <a href="" target="blank" className="fab fa-mail"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- FOOTER END --> */}

      {/* <!-- BUTTON TOP START --> */}

      {fixLogin && (
        <Login
          fixLoginToggle={() => toggleFixLogin()}
          fixLogin={fixLogin}
          fixSignUpToggle={() => fixSignUpToggle()}
          fixSignUp={setFixSignUp}
          fixEmail={setFixVerifyEmail}
        />
      )}
      {fixSignUp && (
        <SignUp
          fixSignUpToggle={() => fixSignUpToggle()}
          fixSignUp={fixSignUp}
          fixLoginToggle={() => toggleFixLogin()}
        />
      )}
      {fixVerifyEmail && (
        <EmailVerify
          fixEmailToggle={() => toggleFixEmail()}
          fixEmail={fixVerifyEmail}
          fixNewPassword={setFixNewPassword}
        />
      )}
      {fixNewPassword && (
        <NewPassword
          fixNewPasswordToggle={() => toggleFixNewPassword()}
          fixNewPassword={setFixNewPassword}
        />
      )}
      <button className="scroltop">
        <span className="fa fa-angle-up relative" id="btn-vibrate"></span>
      </button>
    </>
  );
}
