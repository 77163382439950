import React, { useState, useEffect } from "react";
import Layout from "../../commen/Layout";
import { MultiSelect } from "react-multi-select-component";
import {
  GetData,
  GetDataWithToken,
  PostDataWithToken,
} from "../../../ApiHelper/ApiHelper";
import moment from "moment/moment";

import Loder from "../../commen/Loder";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ModalBody, Modal } from "reactstrap";
import {
  actionBusinessDetailId,
  businessDetailIdd,
  countryName,
} from "../../../store/Action";
import { StreetViewPanorama } from "react-google-maps";
import { WhatsappShareButton } from "react-share";
import Login from "../../Auth/Login";
import Cookies from "js-cookie";
import { useWatch } from "react-hook-form";
import Swal from "sweetalert2";
import SignUp from "../../Auth/SignUp";
import EmailVerify from "../../Auth/EmailVerify";
import NewPassword from "../../Auth/NewPassword";

export default function Deshboard() {
  const [fixLogin, setFixLogin] = useState(false);
  const toggleFixLogin = () => {
    setFixLogin(!fixLogin);
  };
  const [fixSignUp, setFixSignUp] = useState(false);
  const fixSignUpToggle = (e) => {
    setFixSignUp(e);
  };
  const [fixVerifyEmail, setFixVerifyEmail] = useState(false);
  const toggleFixEmail = () => {
    setFixVerifyEmail(!fixVerifyEmail);
  };
  const [fixNewPassword, setFixNewPassword] = useState(false);
  const toggleFixNewPassword = () => {
    setFixNewPassword(!fixVerifyEmail);
  };

  // const [resentData, setResentData] = useState([]);
  // const [findShopId, setFindShopId] = useState("");
  // const [showSuggestions, setShowSuggestions] = useState(false);
  // const [colorRed, setColorRed] = useState(true);
  const [populerSearch, setPopularSearch] = useState([]);

  const [resentSearch, setResentSearch] = useState([]);
  const [wishListColor, setWishListColor] = useState({});
  const [resentData, setResentData] = useState([]);
  const [findShopId, setFindShopId] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [product, setProduct] = useState([]);
  const [images, setImages] = useState([]);
  const [info, setInfo] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const toggleProductModal = () => setProductModal(!productModal);
  const [selected, setSelected] = useState([]);
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [option, setOption] = useState([]);
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentLocationName, setCurrentLocationName] = useState("");
  const [deshboardData, setDeshboardData] = useState([]);
  const [componentLoader, setComponentLoader] = useState(true);
  const [callApi, setCallApi] = useState(true);
  const reduxCountryName = useSelector((state) => state?.countryName?.action);
  const [showLogin, setShowLogin] = useState(false);
  const toggleshowLogin = () => setShowLogin(!showLogin);

  const location = useSelector((state) => state?.loctionn?.action?.location);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const id = useLocation();
  const countryNameRedux = useSelector((state) => state?.countryName?.action);

  let token = Cookies.get("token");

  let access_token = useSelector((state) => {
    return state?.accessToken?.action;
  });

  useEffect(() => {
    if (reduxCountryName) {
      GetData(`auth/get-popular-search?country=${reduxCountryName}`).then(
        (res) => {
          if (res.status == true) {
            setPopularSearch(res?.data);
          }
        }
      );
    }

    GetData("category/get-category")
      .then((data) => {
        if (data.status == true) {
          setComponentLoader(false);
          return data.data;
        }
      })
      .then((data) => {
        setOption(data);
      });

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        if (countryNameRedux) {
          GetData(
            `business/customer-dashboard?lat=${latitude}&lng=${longitude}&country=${countryNameRedux}`
          ).then((data) => {
            setDeshboardData(data.data);
          });
        }

        setCurrentLocation({ latitude: latitude, longitude: longitude });
      },
      (error) => console.error(error)
    );
  }, [countryNameRedux]);
  // const handleFindShop = async () => {
  //   await GetData(
  //     `business/customer-dashboard?=address${query}`
  //   ).then((data) => {
  //     console.log(data.data);
  //     setDeshboardData(data.data);
  //   });
  // }

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("resentSearch")) || [];
    setResentSearch(data);
  }, []);

  useEffect(() => {
    if (currentLocation) {
      GetData(
        `my-location?lat=${currentLocation.latitude}&lng=${currentLocation.longitude}`
      ).then((response) => {
        setCurrentLocationName(response.data);
      });
    }
  }, [currentLocation]);

  const optionLabels =
    option &&
    option?.length > 0 &&
    option?.map((item) => {
      return { label: item.name, value: item.id };
    });

  const handleSelectedChanged = (selected) => {
    setSelected(selected);
  };

  useEffect(() => {
    if (query?.length > 0) {
      GetData(
        `auth/search-business?name=${query}&country=${countryNameRedux}`
      ).then((data) => {
        setSuggestions(data.data);
      });

      if (query.length > 0) {
        GetData();
      } else {
        setSuggestions([]);
      }
    }
    // if (countryNameRedux != undefined) {
    //   GetData(
    //     `auth/search-business?name=${query}&country=${countryNameRedux}`
    //   ).then((res) => {
    //     setResentData(res.data);
    //   });
    // }

    // GetData(
    //   `auth/search-business?name=${query}&country=${countryNameRedux}`
    // ).then((res) => {
    //   setResentData(res.data);
    // });
  }, [query, countryNameRedux]);

  const handleQueryChange = (event) => {
    setShowSuggestions(true);
    setQuery(event.target.value);
  };

  const handleSetId = (id, name) => {
    // console.log(id);
    //  localStorage.setItem("businessId", JSON.stringify(id));

    // const businessId = JSON.parse(localStorage.getItem("businessId"));
    // dispatch(actionBusinessDetailId.businessDetailId(businessId));

    // const businessId = JSON.parse(localStorage.getItem("businessId"))
    // dispatch(actionBusinessDetailId.businessDetailId(businessId))
    navigate(`/businessdetail?id=${id}`, {
      state: {
        id: id,
        name: name
      },
    });
  };

  // const handleShowCarrerOnLogin = (id) => {
  //   if (token) {
  //     navigate("/careerdetail", { state: { id: id } })
  //   } else {
  //     setShowLogin(!showLogin)
  //   }
  //   // onClick={() => handleShowCarrerOnLogin(item?.id)}

  // }

  const handleproductDetail = (id) => {
    setComponentLoader(true);
    GetDataWithToken(`product/product-details/${id}`).then((res) => {
      let img = JSON.parse(res?.data?.data?.images);
      let info = JSON.parse(res?.data?.data?.info);
      setImages(img);
      setInfo(info);
      setProduct(res?.data);
      setWishListColor(res?.data?.wishlist);
      if (res) {
        toggleProductModal();
        setComponentLoader(false);
      }
    });
  };
  const keys = Object?.keys(info);

  const FormetDate = (data) => {
    const date1 = JSON.parse(data);
    const date = new Date(date1.Date);
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace(/(\d+)\/(\w+)\/(\d+)/, "$1/$2/$3".toLowerCase());

    return formattedDate; // Output: "3/may/2023"
  };

  // const handleWishList = (id) => {
  //   GetDataWithToken(`product/add-and-delete-whislist?productId=${id}`)
  // }
  const handleWishList = async (id) => {
    try {
      const response = await GetDataWithToken(
        `product/add-and-delete-wishlist?productId=${id}`
      );
      GetDataWithToken(`product/product-details/${id}`).then((res) => {
        setProduct(res?.data);
      });
      if (response.data) {
        setWishListColor(response.message);

        // do something with the response
      }
    } catch (error) {
      console.error(error); // handle the error
    }
  };

  const handleSetResentSearch = (item, id, type, businessId) => {
    const resentSearch = JSON.parse(localStorage.getItem("resentSearch")) || [];

    const resentObject = {
      name: item,
      id: id,
      type: type,
      businessId: businessId,
    };

    if (resentSearch?.length > 10) {
      resentSearch.shift();
    }
    resentSearch.push(resentObject);

    localStorage.setItem("resentSearch", JSON.stringify(resentSearch));
  };

  const handleShowResentPopulerSearch = () => { };

  return (
    <>
      {componentLoader ? (
        <Loder />
      ) : (
        <Layout>
          <div className="page-content">
            {/* <!--Banner Start--> */}
            <div
              className="twm-home2-banner-section site-bg-gray bg-cover"
              style={{
                backgroundImage: "url(images/main-slider/slider2/bg1.jpg)",
              }}
            >
              <div className="row">
                {/* <!--Left Section--> */}
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="twm-bnr-left-section">
                    <div className="twm-bnr-title-large">
                      Find the best services near you with{" "}
                      <span className="site-text-primary"> Shop Spot.</span>
                      {/* <!-- <br />
                      <small> Porducts and Services</small> --> */}
                    </div>
                    <div className="twm-bnr-discription">
                      Shop Spot is the one stop solution for all your needs.
                      Find everything you need right where you are with just one
                      click.
                    </div>
                    <Link to={"/business"} className="site-button">
                      Get Started
                    </Link>
                  </div>
                </div>

                {/* <!--right Section--> */}
                <div className="col-xl-6 col-lg-6 col-md-12 twm-bnr-right-section">
                  <div className="twm-bnr2-right-content">
                    <div className="twm-img-bg-circle-area2">
                      <div className="twm-outline-ring-wrap">
                        <div className="twm-outline-ring-dott-wrap">
                          <span className="outline-dot-1"></span>
                          <span className="outline-dot-2"></span>
                          <span className="outline-dot-3"></span>
                          {/* <!--Samll Ring Left--> */}
                          <div className="twm-small-ring-l scale-up-center"></div>
                        </div>
                      </div>
                    </div>

                    <div className="twm-home-2-bnr-images">
                      <div className="bnr-image-1">
                        <img src="images/dashboard.png" alt="" />
                      </div>
                      <div className="bnr-image-2">
                        <img
                          src="images/main-slider/slider2/right-pic-2.jpg"
                          alt=""
                        />
                      </div>
                      <div className="twm-small-ring-2 scale-up-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Search Bar--> */}
            <div className="twm-search-bar-2-wrap">
              <div className="container">
                <div className="twm-search-bar-2-inner">
                  <div class="row mb-4">
                    <div class="col-md-12">
                      <div class="twm-bnr-search-bar position-relative">
                        <form>
                          <div class="row">
                            {/* <!--Location--> */}
                            <div class="form-group col-12">
                              <div class="form-group">
                                <label>Search</label>
                                <div class="twm-inputicon-box">
                                  <input
                                    name="Search"
                                    type="text"
                                    required
                                    class="form-control"
                                    placeholder="Search Restaurant, Hotels, Food, Business..."
                                    value={query}
                                    onChange={handleQueryChange}
                                    onClick={handleShowResentPopulerSearch}
                                  />
                                  {/* <!-- <i class="twm-input-icon fas fa-search"></i> --> */}
                                </div>
                              </div>
                            </div>

                            {/* <!--Find job btn--> */}
                            <div class="form-group col-xl-3 col-lg-6 col-md-6 d-none">
                              <Link
                                type="button"
                                class="site-button text-center"
                                // to={query ? "/businessdetail" : "#"}
                                state={{ id: findShopId }}
                              >
                                Find Shop
                              </Link>
                            </div>
                          </div>
                        </form>
                        {/* Search everything start here... */}

                        {query?.length > 0 && (
                          <div className="search-history show">
                            <div className="search-every mt-4">
                              {suggestions?.business?.length > 0 && (
                                <h4 className="my-3">Business</h4>
                              )}
                              <div className="d-flex flex-wrap">
                                {suggestions?.business?.length > 0 &&
                                  suggestions?.business?.map((item, key) => (
                                    <Link
                                      onClick={() =>
                                        handleSetResentSearch(
                                          item?.name,
                                          item?.id,
                                          "business"
                                        )
                                      }
                                      to={`/businessdetail?id=${item?.id}`}
                                      state={{ id: item?.id, name: item?.name }}
                                      className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                    >
                                      <span className="search-img me-2">
                                        <img
                                          className=""
                                          src={item?.business_licence}
                                          alt="#"
                                        />
                                      </span>
                                      {item?.name}
                                    </Link>
                                  ))}
                              </div>

                              {suggestions?.carrer?.length > 0 && (
                                <h4 className="my-3">Carrer</h4>
                              )}
                              <div className="d-flex flex-wrap">
                                {suggestions?.carrer?.length > 0 &&
                                  suggestions?.carrer?.map((item, key) => (
                                    <Link
                                      onClick={() =>
                                        handleSetResentSearch(
                                          item?.post_name,
                                          item?.id,
                                          "Carrer"
                                        )
                                      }
                                      to={"/careerdetail"}
                                      state={{ id: item?.id }}
                                      className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                    >
                                      <span className="search-img me-2">
                                        <img
                                          className=""
                                          src={"images/banner/logo(1).png"}
                                          alt="#"
                                        />
                                      </span>
                                      {item?.post_name}
                                    </Link>
                                  ))}
                              </div>

                              {suggestions?.category?.length > 0 && (
                                <h4 className="my-3">Categories</h4>
                              )}
                              <div className="d-flex flex-wrap">
                                {suggestions?.category?.length > 0 &&
                                  suggestions?.category?.map((item, key) => (
                                    <Link
                                      onClick={() =>
                                        handleSetResentSearch(
                                          item?.name,
                                          item?.id,
                                          "Categories"
                                        )
                                      }
                                      to={`/business`}
                                      state={{ id: item?.id }}
                                      className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                    >
                                      <span className="search-img me-2">
                                        <img
                                          className=""
                                          src={item?.image}
                                          alt="#"
                                        />
                                      </span>
                                      {item?.name}
                                    </Link>
                                  ))}
                              </div>

                              {suggestions?.product?.length > 0 && (
                                <h4 className="my-3">Products</h4>
                              )}
                              <div className="d-flex flex-wrap">
                                {suggestions?.product?.length > 0 &&
                                  suggestions?.product?.map((item, key) => (
                                    <Link
                                      to={`/businessdetail?id=${item?.businessId}`}
                                      state={{ id: item?.businessId, name: item?.business?.name }}
                                      onClick={() =>
                                        handleSetResentSearch(
                                          item?.name,
                                          item?.id,
                                          "Products",
                                          item?.businessId
                                        )
                                      }
                                      className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                    >
                                      <span className="search-img me-2">
                                        <img
                                          className=""
                                          src={
                                            item?.images &&
                                            JSON?.parse(item?.images)[0]
                                          }
                                          alt=""
                                        />
                                      </span>
                                      {item?.name}
                                    </Link>
                                  ))}
                              </div>

                              {suggestions?.experience?.length > 0 && (
                                <h4 className="my-3">Experience</h4>
                              )}
                              <div className="d-flex flex-wrap">
                                {suggestions?.experience?.length > 0 &&
                                  suggestions?.experience?.map((item, key) => (
                                    <Link
                                      to={"/latestexoerience"}
                                      state={{ id: item?.id }}
                                      onClick={() =>
                                        handleSetResentSearch(
                                          item?.name,
                                          item?.id,
                                          "Experience"
                                        )
                                      }
                                      className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                    >
                                      <span className="search-img me-2">
                                        <img
                                          className=""
                                          src={item?.image}
                                          alt=""
                                        />
                                      </span>
                                      {item?.name}
                                    </Link>
                                  ))}
                              </div>

                              {suggestions?.freelance?.length > 0 && (
                                <h4 className="my-3">Freelance</h4>
                              )}
                              <div className="d-flex flex-wrap">
                                {suggestions?.freelance?.length > 0 &&
                                  suggestions?.freelance?.map((item, key) => (
                                    <Link
                                      onClick={() =>
                                        handleSetResentSearch(
                                          item?.name,
                                          item?.id,
                                          "Freelance"
                                        )
                                      }
                                      to={"/freelancedetail"}
                                      state={{ id: item?.id }}
                                      className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                    >
                                      <span className="search-img me-2">
                                        <img
                                          className=""
                                          src={item?.business_licence}
                                          alt="#"
                                        />
                                      </span>
                                      {item?.name}
                                    </Link>
                                  ))}
                              </div>
                            </div>

                            <div className="Recent-search">
                              <h4 className="mb-3">Recent search</h4>
                              <div className="d-flex flex-wrap">
                                {resentSearch?.length > 0 &&
                                  resentSearch?.map((item, key) => (
                                    <Link
                                      to={
                                        item?.type == "business"
                                          ? `/businessdetail?id=${item?.id}`
                                          : item?.type == "Carrer"
                                            ? "/careerdetail"
                                            : item?.type == "Freelance"
                                              ? "/freelancedetail"
                                              : item?.type == "Experience"
                                                ? "/latestexoerience"
                                                : item?.type == "Products"
                                                  ? `/businessdetail?id=${item?.businessId}`
                                                  : item?.type == "Categories"
                                                    ? "/business"
                                                    : "/"
                                      }
                                      state={{ id: item?.id, name: item?.name }}
                                      className="btn btn-light rounded-pill me-2 mb-2"
                                      key={key}
                                    >
                                      <span className="me-2">
                                        <i className="fas fa-history"></i>
                                      </span>
                                      {item?.name}
                                    </Link>
                                  ))}
                              </div>
                            </div>
                            <div className="popular-search mt-4">
                              <h4 className="mb-3">Popular search</h4>
                              {query?.length > 0 && (
                                <div className="d-flex flex-wrap">
                                  {populerSearch?.business?.length > 0 &&
                                    populerSearch?.business?.map(
                                      (item, key) => (
                                        <Link
                                          to={`/businessdetail?id=${item?.id}`}
                                          state={{
                                            id: item?.id, name: item?.name
                                          }}
                                          className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                        >
                                          <span className="search-img me-2">
                                            <img
                                              className=""
                                              src={item?.business_licence}
                                              alt="#"
                                            />
                                          </span>
                                          {item?.name}
                                        </Link>
                                      )
                                    )}

                                  {populerSearch?.carrer?.length > 0 &&
                                    populerSearch?.carrer?.map((item, key) => (
                                      <Link
                                        to={`/careerdetail`}
                                        state={{ id: item?.id }}
                                        className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                      >
                                        <span className="search-img me-2">
                                          <img
                                            className=""
                                            src={"images/banner/logo(1).png"}
                                            alt="#"
                                          />
                                        </span>
                                        {item?.post_name}
                                      </Link>
                                    ))}

                                  {populerSearch?.category?.length > 0 &&
                                    populerSearch?.category?.map(
                                      (item, key) => (
                                        <Link
                                          to={`/business`}
                                          state={{ id: item?.id }}
                                          className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                        >
                                          <span className="search-img me-2">
                                            <img
                                              className=""
                                              src={item?.image}
                                              alt="#"
                                            />
                                          </span>
                                          {item?.name}
                                        </Link>
                                      )
                                    )}

                                  {populerSearch?.experience?.length > 0 &&
                                    populerSearch?.experience?.map(
                                      (item, key) => (
                                        <Link
                                          to={`/latestexoerience`}
                                          state={{ id: item?.id }}
                                          className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                        >
                                          <span className="search-img me-2">
                                            <img
                                              className=""
                                              src={item?.image}
                                              alt="#"
                                            />
                                          </span>
                                          {item?.name}
                                        </Link>
                                      )
                                    )}

                                  {populerSearch?.freelance?.length > 0 &&
                                    populerSearch?.freelance?.map(
                                      (item, key) => (
                                        <Link
                                          to={`/freelancedetail`}
                                          state={{ id: item?.id }}
                                          className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                        >
                                          <span className="search-img me-2">
                                            <img
                                              className=""
                                              src={item?.business_licence}
                                              alt="#"
                                            />
                                          </span>
                                          {item?.name}
                                        </Link>
                                      )
                                    )}

                                  {populerSearch?.product?.length > 0 &&
                                    populerSearch?.product?.map((item, key) => (
                                      <Link
                                        to={`/businessdetail?id=${item?.id}`}
                                        state={{ id: item?.id, name: item?.business?.name }}
                                        className="btn btn-light rounded-pill me-2 mb-2 d-flex align-items-center justify-content-center"
                                      >
                                        <span className="search-img me-2">
                                          <img
                                            className=""
                                            src={
                                              item?.images &&
                                              JSON?.parse(item?.images)[0]
                                            }
                                            alt="#"
                                          />
                                        </span>
                                        {item?.name}
                                      </Link>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {/* Search everything end here...  */}
                      </div>
                    </div>
                  </div>
                  <div>
                    {showSuggestions &&
                      suggestions &&
                      suggestions?.length > 0 &&
                      suggestions?.map((item, key) => (
                        <ul key={key}>
                          <li
                            onClick={async (e) => {
                              await setQuery(e.target.innerHTML);
                              setFindShopId(item?.id);
                              setShowSuggestions(false);
                            }}
                          >
                            {item.name}
                          </li>
                        </ul>
                      ))}
                  </div>
                  {/* <!-- Current Location start here... --> */}
                  <div className="row mb-0">
                    <div className="col-12">
                      <form>
                        <div className="form-floating Current-Location mb-3">
                          <span
                            className="form-select pb-0 bg-white border-bottom"
                            id="CurrentLocation"
                            aria-label="Floating label select example"
                          >
                            <option selected className="ms-4">
                              {currentLocationName}
                            </option>
                          </span>
                          <label for="CurrentLocation">Your Location</label>
                          <div className="current-location-img">
                            <img
                              className="w-100"
                              src="./images/location-current-svgrepo-com.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* <!-- Current Location end here... --> */}
                  <div className="twm-bnr-popular-search d-none">
                    <span className="twm-title">Popular Searches:</span>
                    <a>Bakery</a> ,<a>Hotel</a> ,<a>Music and DJ</a> ,
                    <a>Architects</a> ...
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Banner End--> */}

            {/* <!-- HOW IT WORK SECTION START --> */}
            <div className="section-full p-t80 site-bg-white twm-how-it-work-area2 d-none">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    {/* <!-- TITLE START--> */}
                    <div className="section-head left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        <div>How It Works</div>
                      </div>
                      <h2 className="wt-title">
                        Follow our steps we will help you.
                      </h2>
                    </div>
                    <ul className="description-list">
                      <li>
                        <i className="feather-check"></i>
                        list your product
                      </li>
                      <li>
                        <i className="feather-check"></i>
                        target customers
                      </li>
                      <li>
                        <i className="feather-check"></i>
                        user-friendly platform
                      </li>
                      <li>
                        <i className="feather-check"></i>
                        find what you’ re looking for
                      </li>
                    </ul>
                    {/* <!-- TITLE END--> */}
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="twm-w-process-steps-2-wrap">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                          <div className="twm-w-process-steps-2">
                            <div className="twm-w-pro-top bg-clr-sky-light">
                              <span className="twm-large-number text-clr-sky">
                                01
                              </span>
                              <h4 className="twm-title mt-0 ms-0">
                                Register
                                <br />
                                Your Account
                              </h4>
                              <p>
                                You need to create an account to find the best
                                and preferred Place.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6">
                          <div className="twm-w-process-steps-2">
                            <div className="twm-w-pro-top bg-clr-yellow-light">
                              <span className="twm-large-number text-clr-yellow">
                                02
                              </span>
                              <h4 className="twm-title mt-0 ms-0">
                                Search <br />
                                Your Place
                              </h4>
                              <p>
                                You need to create an account to find the best
                                and preferred Place.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6">
                          <div className="twm-w-process-steps-2">
                            <div className="twm-w-pro-top bg-clr-pink-light">
                              <span className="twm-large-number text-clr-pink">
                                03
                              </span>

                              <h4 className="twm-title mt-0 ms-0">
                                Apply <br />
                                For Dream Place
                              </h4>
                              <p>
                                You need to create an account to find the best
                                and preferred Place.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6">
                          <div className="twm-w-process-steps-2">
                            <div className="twm-w-pro-top bg-clr-green-light">
                              <span className="twm-large-number text-clr-green">
                                04
                              </span>
                              <h4 className="twm-title mt-0 ms-0">
                                Upload <br />
                                Your Resume
                              </h4>
                              <p>
                                You need to create an account to find the best
                                and preferred Place.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="twm-how-it-work-section"></div>
              </div>
            </div>
            {/* <!-- HOW IT WORK SECTION END --> */}

            {/* <!-- Popular category SECTION START --> */}
            <div className="section-full p-t50 p-b50 site-bg-white twm-jobatglance-wrap8">
              <div className="container">
                <div className="wt-separator-two-part">
                  <div className="row wt-separator-two-part-row">
                    <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
                      {/* <!-- TITLE START--> */}
                      <div className="section-head left wt-small-separator-outer">
                        <div className="wt-small-separator site-text-primary">
                          <div>View All Categories</div>
                        </div>
                        <h2 className="wt-title">
                          Discover the ideal category that suits your needs
                        </h2>
                      </div>
                      {/* <!-- TITLE END--> */}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
                      <Link to={"/allcategory"} className="site-button">
                        View All Category
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="twm-jobatglance-h8">
                  <div className="row justify-content-start">
                    {/* <!--1--> */}
                    {deshboardData?.category?.length == 0 ? (

                      <div className="no-content">
                        <img src={"/images/no-business.png"} alt="" />
                        <h2>
                          There is no Category in this country please salect
                          another country
                        </h2>
                      </div>
                    ) : (
                      deshboardData &&
                      deshboardData.category?.map((item, i) => (
                        <div className="col-lg-3 col-md-4 col-12">
                          <div className="job-categories-home-8 mb-3 position-relative">
                            <div className="twm-media cat-bg-clr-3">
                              <img src={item.image} alt="" />
                            </div>
                            <Link
                              className="stretched-link"
                              to={"/business"}
                              state={{ id: item.id }}
                            >
                              {item.name}
                            </Link>
                            <div className="twm-content">
                              <div className="twm-jobs-available">
                                {item?.businesses?.length + " Vendor"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Popular category SECTION END --> */}

            {/* <!-- BUSINESS START --> */}

            <div className="section-full p-t50 p-b50 site-bg-gray twm-bg-ring-wrap2">
              <div className="twm-bg-ring-right"></div>
              <div className="twm-bg-ring-left"></div>

              <div className="container">
                <div className="wt-separator-two-part">
                  <div className="row wt-separator-two-part-row">
                    <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
                      {/* <!-- TITLE START--> */}
                      <div className="section-head left wt-small-separator-outer">
                        <div className="wt-small-separator site-text-primary">
                          <div>All Business</div>
                        </div>
                        <h2 className="wt-title">
                          Discover every business category from A to Z, under
                          one roof.
                        </h2>
                      </div>
                      {/* <!-- TITLE END--> */}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
                      <Link to={"/business"} className="site-button">
                        Browse All Business
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="section-content">
                  <div className="twm-jobs-grid-wrap">
                    <div className="row">
                      {deshboardData?.business?.length == 0 ? (
                        <div className="no-content">
                          <img src={"/images/no-business.png"} alt="" />
                          <h2>
                            There is no Business in this country please salect
                            another country
                          </h2>
                        </div>
                      ) : (
                        deshboardData &&
                        deshboardData?.business?.length > 0 &&
                        deshboardData?.business?.map((item, i) => (
                          <div className="col-lg-4 col-md-6">
                            <div className="twm-jobs-grid-style1 m-b30">
                              <div className="twm-media">
                                <img src={item?.business_licence} alt="#" />
                              </div>
                              <Link
                                to={"/business"}
                                state={{ id: item?.category?.id }}
                                className="twm-job-post-duration bg-success rounded-pill"
                              >
                                {item?.category?.name}
                              </Link>
                              <div className="twm-mid-content">
                                <a
                                  onClick={() => handleSetId(item.id, item.name)}
                                  className="twm-job-title cursor-pointer"
                                >
                                  <h4>{item?.name}</h4>
                                </a>
                                <p className="twm-job-address text-truncate">
                                  {item?.address}
                                </p>
                                {/* <!-- <a
                    href="https://themeforest.net/user/thewebmax/portfolio"
                    className="twm-job-websites site-text-primary"
                    >https://thewebmax.com</a
                  > --> */}
                              </div>
                              <div className="twm-right-content">
                                <span className="twm-jobs-browse site-text-primary cursor-pointer">
                                  {item?.distance?.toFixed(2) + " " + "KM"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- BUSINESS END --> */}

            {/* <!-- Related Products START --> */}
            <div className="section-full p-t50 p-b50">
              <div className="container">
                {/* <!-- TITLE START--> */}
                <div className="section-head center wt-small-separator-outer mb-0">
                  <div className="wt-small-separator site-text-primary">
                    <div>Our Products</div>
                  </div>
                  <h2 className="wt-title">Trending Products</h2>
                </div>
                {/* <!-- TITLE END--> */}

                <div className="section-content">
                  <div className="twm-blog-post-3-outer-wrap">
                    <div className="row d-flex justify-content-center">
                      {deshboardData?.product?.length == 0 ? (
                        <div className="no-content">
                          <img src={"/images/no-business.png"} alt="" />
                          <h2>
                            There is no Products in this country please salect
                            another country
                          </h2>
                        </div>
                      ) : (
                        deshboardData &&
                        deshboardData?.product?.length > 0 &&
                        deshboardData?.product?.map((item, i) => (
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            {/* <!--Block one--> */}
                            <div className="blog-post twm-blog-post-3-outer">
                              <div className="wt-post-media">
                                <a
                                  onClick={() => {
                                    access_token &&
                                      handleproductDetail(item.id);
                                    !access_token && toggleFixLogin();
                                  }}
                                >
                                  <img
                                    className="product-img"
                                    src={JSON.parse(item.images)?.[0]}
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div className="wt-post-info">
                                <div className="wt-post-title">
                                  <h4 className="post-title text-truncate">
                                    <a>{item?.name}</a>
                                  </h4>
                                </div>
                                <div className="wt-post-meta product-detail">
                                  <ul>
                                    <li className="post-author">
                                      <span className="twm-media">
                                        <img
                                          src={item.business?.business_licence}
                                          alt="#"
                                        />
                                      </span>
                                      {"By" + "  "}
                                      <Link
                                        to={`/businessdetail?id=${item?.business?.id}`}
                                        state={{ id: item?.business?.id, name: item?.business?.name }}
                                        className="ms-1"
                                      >
                                        {" " + item?.business?.name}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Related Products END --> */}

            {/* <!-- Business Event Start --> */}
            <div className="section-full p-t50 p-b50 site-bg-gray twm-bg-ring-wrap2">
              <div className="twm-bg-ring-right"></div>
              <div className="twm-bg-ring-left"></div>
              <div className="container">
                <div className="wt-separator-two-part">
                  <div className="row wt-separator-two-part-row">
                    <div className="col-xl-8 col-lg-8 col-md-12 wt-separator-two-part-left">
                      {/* <!-- TITLE START--> */}
                      <div className="section-head left wt-small-separator-outer">
                        <div className="wt-small-separator site-text-primary">
                          <div>New Events</div>
                        </div>
                        <h2 className="wt-title">
                          Discover the latest events from all your favorite
                          businesses in one place
                        </h2>
                      </div>
                      {/* <!-- TITLE END--> */}
                    </div>
                  </div>
                </div>

                <div className="section-content">
                  <div className="twm-jobs-grid-wrap">
                    <div className="row">
                      {deshboardData?.event?.length == 0 ? (
                        <div className="no-content">
                          <img src={"/images/no-business.png"} alt="" />
                          <h2>
                            There is no Events in this country please salect
                            another country
                          </h2>
                        </div>
                      ) : (
                        deshboardData &&
                        deshboardData?.event?.length > 0 &&
                        deshboardData?.event?.map((item, key) => (
                          <div className="col-lg-4 col-md-6" key={key}>
                            <div className="twm-jobs-grid-style1 m-b30 p-0 event-card">
                              <div className="event-img">
                                <img src={JSON.parse(item?.image)[0]} alt="" />
                              </div>
                              <div className="card-body position-relative">
                                <div className="twm-media">
                                  <img
                                    src={item?.business?.business_licence}
                                    alt="#"
                                  />
                                </div>
                                <h5>{item?.name}</h5>
                                <p className="event-time">
                                  {moment(item?.date).format("MMMM Do YYYY")}{" "}
                                  {" " + item?.time &&
                                    JSON.parse(item?.time).to}{" "}
                                  -{item?.time && JSON.parse(item?.time).from}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Business Event END --> */}

            {/* <!-- Featured Freelance SECTION START --> */}
            <div className="section-full p-t50 p-b50 site-bg-white twm-hpage-6-featured-outer">
              {/* <!-- TITLE START--> */}
              <div className="section-head center wt-small-separator-outer">
                <div className="wt-small-separator site-text-primary">
                  <div>Top Careers</div>
                </div>
                <h2 className="wt-title">
                  Take the next step in your career: Discover opportunities and
                  grow.
                </h2>
              </div>
              {/* <!-- TITLE END--> */}

              <div className="twm-hpage-6-featured-area">
                <div className="twm-hpage-6-featured-bg-warp">
                  <div className="twm-media">
                    <img src="images/home-6/banner-pic.png" alt="#" />
                  </div>
                </div>

                <div className="container">
                  <div className="twm-hpage-6-featured-content-warp m-b30">
                    <div className="row">
                      <div className="col-lg-8 col-md-12">
                        <div className="row">
                          {deshboardData?.career?.length == 0 ? (
                            <div className="no-content">
                              <img src={"/images/no-business.png"} alt="" />
                              <h2>
                                There is no Careers in this country please salect
                                another country
                              </h2>
                            </div>
                          ) : (
                            deshboardData &&
                            deshboardData?.career?.length > 0 &&
                            deshboardData?.career?.map((item, key) => (
                              <div className="col-lg-6 col-md-6 m-b30">
                                <div className="hpage-6-featured-block">
                                  <div className="inner-content" key={key}>
                                    <div className="mid-content">
                                      <div className="company-logo">
                                        <img
                                          src={item?.business?.business_licence}
                                          alt="#"
                                        />
                                      </div>
                                      <div className="company-info">
                                        <Link
                                          to={`/businessdetail?id=${item?.business?.id}`} state={{ id: item?.id, name: item?.business?.name }}
                                          className="company-name"
                                        >
                                          {item?.business?.name}
                                        </Link>
                                        <p className="company-address">
                                          {/* <a
                                            onClick={() => { token ? navigate("/careerdetail", { state: { id: item?.id } }) : setShowLogin(!showLogin) }}

                                            className="aplybtn"
                                          >
                                            <i className="fas fa-chevron-right"></i>
                                          </a>                                      */}
                                          {item?.address}.
                                        </p>
                                      </div>
                                    </div>
                                    <div className="bottom-content">
                                      <h4 className="job-name-title">
                                        {item?.post_name}
                                      </h4>
                                    </div>
                                    <div className="aply-btn-area">
                                      <a
                                        onClick={() => {
                                          navigate("/careerdetail", {
                                            state: { id: item?.id },
                                          });
                                        }}
                                        // href="javascript:void(0)"
                                        className="aplybtn"
                                      >
                                        <i className="fas fa-chevron-right"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-center job-categories-btn">
                      <a
                        onClick={() => {
                          navigate("/careerlist")
                        }}
                        className="site-button"
                      >
                        Show more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Featured Freelance SECTION END --> */}

            {/* <!-- OUR EXPERIENCE START --> */}
            <div className="section-full p-t50 p-b50 site-bg-gray">
              <div className="container">
                {/* <!-- TITLE START--> */}
                <div className="section-head center wt-small-separator-outer">
                  <div className="wt-small-separator site-text-primary">
                    <div>Our Experience</div>
                  </div>
                  <h2 className="wt-title">Latest Experience</h2>
                </div>
                {/* <!-- TITLE END--> */}

                <div className="section-content">
                  <div className="twm-blog-post-1-outer-wrap">
                    <div className="owl-carousel d-block twm-la-home-blog owl-btn-bottom-center">
                      {
                        <div className="item">
                          <OwlCarousel
                            className="owl-theme"
                            loop
                            items={3}
                            margin={10}
                            nav
                            navText={
                              [
                                // `< i className="fas fa-chevron-left"></i>`,
                                // `<?i className="fas fa-chevron-right"></i>`,
                              ]
                            }
                            // autoPlay={true}
                            // autoplayTimeout={3000}
                            dots={true}
                          >
                            {deshboardData &&
                              deshboardData?.experience?.length > 0 &&
                              deshboardData?.experience?.map((item, key) => (
                                <div
                                  className="blog-post twm-blog-post-1-outer"
                                  key={key}
                                >
                                  <div className="wt-post-media">
                                    <Link
                                      to={"/latestexoerience"}
                                      state={{ id: item?.id }}
                                    >
                                      <img src={item?.image} alt="" />
                                    </Link>
                                  </div>
                                  <div className="wt-post-info">
                                    <div className="wt-post-meta">
                                      <ul>
                                        {/* <!-- <li className="post-date">April 28 2023</li> --> */}
                                        <li className="post-author">
                                          <Link
                                            to={"/latestexoerience"}
                                            state={{ id: item?.id }}
                                          >
                                            {item?.name}
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="wt-post-title">
                                      <h4 className="post-title">
                                        <a>{item?.description}</a>
                                      </h4>
                                    </div>
                                    <div className="wt-post-readmore">
                                      <a className="site-button-a site-text-primary">
                                        {item?.place}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            {/* Add more items as needed */}
                          </OwlCarousel>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- OUR EXPERIENCE END --> */}

            {/* <!-- CAREER SECTION START --> */}
            <div className="section-full p-t50 p-b50 site-bg-white twm-hpage-6-featured-outer">
              {/* <!-- TITLE START--> */}
              <div className="section-head center wt-small-separator-outer">
                <div className="wt-small-separator site-text-primary">
                  <div>Top Freelance</div>
                </div>
                <h2 className="wt-title">
                  Discover our talented network of freelance professionals.
                </h2>
              </div>
              {/* <!-- BLOG SECTION START --> */}
              <div className="section-content">
                <div className="container">
                  <div className="row">
                    {deshboardData?.freelance?.length == 0 ? (
                      <div className="no-content">
                        <img src={"/images/no-business.png"} alt="" />
                        <h2>
                          There is no Freelance in this country please salect
                          another country
                        </h2>
                      </div>
                    ) : (
                      deshboardData &&
                      deshboardData?.freelance?.length > 0 &&
                      deshboardData?.freelance?.map((item, key) => (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                          <div className="activity card bg-light">
                            <div className="event-img">
                              <img src={JSON.parse(item?.images)[0]} alt="" />
                            </div>
                            <div className="card-body position-relative">
                              <div className="event-logo">
                                <img src={item?.business_licence} alt="" />
                              </div>
                              <span className="badge bg-primary rounded-pill mb-2">
                                {JSON.parse(item?.info).type}
                              </span>
                              <h5 className="text-truncate">{item?.name}</h5>
                              <div className="d-flex align-items-start justify-content-between">
                                <p>{FormetDate(item?.info)}</p>
                                <span className="ms-auto text-bold">
                                  {JSON.parse(item?.info).Available}
                                </span>
                              </div>
                              <div className="details">
                                <a
                                  // to={"/freelancedetail"}
                                  // state={{ id: item?.id }}
                                  onClick={() => {
                                    token
                                      ? navigate("/freelancedetail", {
                                        state: { id: item?.id },
                                      })
                                      : toggleFixLogin();
                                  }}
                                  className="btn stretched-link p-0"
                                >
                                  {item?.address}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- CAREER SECTION END --> */}

            {/* <!-- REFER & EARN START --> */}
            <div className="section-full site-bg-white h-page6-getjobs-wrap">
              <div className="container">
                <div className="h-page-6-getjobs-wrap">
                  <div className="row">
                    <div className="col-lg-7 col-md-12">
                      <div className="h-page-6-getjobs-left">
                        <div className="twm-media">
                          <img src="./images/rewards.png" alt="#" />
                          <div className="twm-media-bg-circle"></div>
                          <div className="twm-media-bg-circle2"></div>
                          <div className="twm-media-bg-circle3">
                            <div className="rotate-center">
                              <span className="ring1"></span>
                              <span className="ring2"></span>
                              <span className="ring3"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-12">
                      <div className="h-page-6-getjobs-right">
                        {/* <!-- TITLE START--> */}
                        <div className="section-head left wt-small-separator-outer">
                          <div className="wt-small-separator site-text-primary">
                            <div>Get Rewards</div>
                          </div>
                          <h2 className="wt-title">
                            Earn
                            <span className="site-text-primary me-1">
                              ShopSpot
                            </span>
                            Loyalty
                            Reward Points
                            By Inviting Friends To Join.
                          </h2>
                          <p className="mb-3">
                            Refer your friends to Shopspot and earn 10 points for every successful
                            referral! Once you collect 100 points, you'll be eligible for a range of exciting gifts and
                            vouchers.
                          </p>
                          <p>
                            It's easy to get started - simply invite your friends to Shopspot using your
                            unique referral link, and watch the points add up. Plus, your friends will also receive a
                            special welcome bonus when they sign up through your link. Start referring today and
                            get rewarded for sharing the Shopspot love!
                          </p>
                        </div>
                        {/* <!-- TITLE END--> */}
                        <div className="twm-read-more">
                          <a
                            className="site-button"
                            onClick={() => {
                              token
                                ? navigate("/profile", { state: { id: "2" } })
                                : toggleFixLogin()
                            }}
                          >
                            Refer to Friend
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- REFER & EARN END --> */}

            {/* <!-- Download START --> */}
            <div className="section-full p-t120 p-b120 twm-for-employee-area site-bg-white">
              <div className="container">
                <div className="section-content">
                  <div className="row">
                    <div className="col-lg-5 col-md-12">
                      <div className="twm-explore-media-wrap">
                        <div className="twm-media">
                          <img src="images/download (1).png" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-7 col-md-12">
                      <div className="twm-explore-content-outer-3">
                        <div className="twm-explore-content-3">
                          <div className="twm-title-large">
                            <h2>Downlaod our mobile App</h2>
                            <p>
                              There are many variations of passages of Lorem
                              Ipsum available, since the when an printer took.
                              lorem Ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </p>
                          </div>
                          <div className="twm-upload-file d-flex">
                            <a
                              target="blank"
                              href={
                                "https://apps.apple.com/in/app/shop-spot-app/id6443730951"
                              }
                              type="button"
                              className="btn w-40"
                            >
                              <img
                                src="./images/apple-storepicon-768x221.png"
                                alt=""
                              />
                            </a>
                            <a
                              href="https://play.google.com/store/apps/details?id=com.cygalmall"
                              target="blank"
                              type="button"
                              className="btn w-40"
                            >
                              <img src="./images/Google-1-768x221.png" alt="" />
                            </a>
                          </div>
                        </div>

                        <div className="twm-l-line-1"></div>
                        <div className="twm-l-line-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              className="modal-dialog-centered modal-lg product-details-modal twm-sign-up"
              isOpen={productModal}
              toggle={toggleProductModal}
            >
              <div className="modal-header mt-0 py-0 border-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    toggleProductModal();
                  }}
                ></button>
              </div>
              <ModalBody>
                {/* <!-- Slider container --> */}
                <div className="product-details">
                  <div id="carouselExample" className="carousel slide">
                    <div className="carousel-inner">
                      <OwlCarousel
                        className="owl-theme"
                        loop
                        items={1}
                        margin={10}
                        nav
                        navText={["<", ">"]}
                        autoPlay={true}
                        autoplayTimeout={3000}
                      >
                        {images &&
                          images?.length > 0 &&
                          images?.map((item, key) => (
                            <div
                              className="carousel-item shadow-sm active"
                              key={key}
                            >
                              <img
                                src={item}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                          ))}
                        {/* Add more items as needed */}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>

                <div className="description mt-3 position-relative">
                  <div className="share-icons">
                    <span
                      className="btn share"
                      onClick={() => handleWishList(product?.data?.id)}
                    >
                      {product?.wishlist === null && (
                        <i className="far fa-heart"></i>
                      )}

                      {product?.wishlist && <i className="fas fa-heart"> </i>}
                    </span>
                    <WhatsappShareButton
                      url={`image=${product?.data?.images &&
                        JSON.parse(product?.data?.images)?.[0]
                        } name=${product?.data?.name}price=${product?.data?.price
                        }description=${product?.data?.description}`}
                    >
                      <span className="btn">
                        <i className="far fa-share-square"></i>
                      </span>
                    </WhatsappShareButton>
                  </div>
                  <h5>{product?.data?.name}</h5>
                  <p>{product?.data?.description}</p>
                  {info &&
                    info.length > 0 &&
                    info.map((item, key) => (
                      <div>
                        <h5>{Object.keys(item)?.[0]}</h5>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>{item[Object.keys(item)?.[0]]}</p>
                          <p>4 windows</p>
                        </div>{" "}
                      </div>
                    ))}
                  <div className="d-flex align-items-center justify-content-between">
                    <h5>Price </h5>
                    <h5>{"BD" + " " + product?.data?.price}</h5>
                  </div>
                  <div className="text-end">
                    {/* <WhatsappShareButton
                      url={`image=${
                        product?.data?.images &&
                        JSON.parse(product?.data?.images)?.[0]
                      } name=${product?.data?.name}price=${
                        product?.data?.price
                      }description=${product?.data?.description}`}
                    >
                      <a
                        // type="submit"
                        className="site-button"
                        style={{ width: "auto", marginLeft: "auto" }}
                      >
                        Book Now
                      </a>
                    </WhatsappShareButton> */}
                    <a
                      className="site-button w-auto mt-3"
                      target="_blank"
                      href={`https://web.whatsapp.com/send?phone=${product?.data?.business?.contact &&
                        JSON.parse(product?.data?.business?.contact)?.whatsapp
                        }&text=Hello product name${product?.data?.name}Price ${product?.data?.price
                        }`}
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            {/* <!-- Download END --> */}
            {
              <Login
                fixLoginToggle={() => toggleFixLogin()}
                fixLogin={fixLogin}
                fixSignUpToggle={() => fixSignUpToggle()}
                fixSignUp={setFixSignUp}
                fixEmail={setFixVerifyEmail}
              />
            }
            {fixSignUp && (
              <SignUp
                fixSignUpToggle={() => fixSignUpToggle()}
                fixSignUp={fixSignUp}
                fixLoginToggle={() => toggleFixLogin()}
              />
            )}
            {fixVerifyEmail && (
              <EmailVerify
                fixEmailToggle={() => toggleFixEmail()}
                fixEmail={fixVerifyEmail}
                fixNewPassword={setFixNewPassword}
              />
            )}
            {fixNewPassword && (
              <NewPassword
                fixNewPasswordToggle={() => toggleFixNewPassword()}
                fixNewPassword={setFixNewPassword}
              />
            )}
          </div>
          {/* <!-- CONTENT END --> */}
        </Layout>
      )}
    </>
  );
}
