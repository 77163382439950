import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useForm } from "react-hook-form";
import { PostData } from "../../ApiHelper/ApiHelper";
import Cookies from "js-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import SignUp from "./SignUp";
import EmailVerify from "./EmailVerify";
import { useDispatch, useSelector } from "react-redux";
import { actionLoginStatus } from "../../store/Action";
import { userDetail } from "../../store/Action";
import Swal from "sweetalert2";
import { actionAccessToken } from "../../store/Action";
import { toast } from "material-react-toastify";


export default function Login(props) {


  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const currentLocation = useSelector((state) => state?.loctionn?.action?.location);




  const clientId =
    "1056687895620-on8ip3n5m5j4f68i5jiuhg9iq3s7pace.apps.googleusercontent.com";
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  const [modal, setModal] = useState(true);
  const toggleModal = () => {
    setModal(!modal);
  };

  const handleLoginSubmit = (data) => {
    setLoading(true);
    PostData("auth/login", data).then((response) => {
      if (response?.status == true) {
        Cookies.set("userDetails", response.user);
        Cookies.set("token", response?.user?.access_token);
        Cookies.set("userid", response?.user?.id);
        Cookies.set("userName", response.user.name);
        setIsLogin(true);
        toast.success(response.message);

        // Swal.fire("Success", response.message, "success");
        dispatch(userDetail.userDetails(response?.user));
        dispatch(actionLoginStatus.loginStatus(true));
        dispatch(actionAccessToken?.accessToken(response?.user?.access_token));
        props?.fixLoginToggle()
        setLoading(false);
        navigate("/");
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error !",
          text: response?.data?.message,
          icon: "error",
          showConfirmButton: true, // Set this option to false to remove the OK button
        });
      }
    });
  };

  const onSuccess = (response) => {
    // setLoading(true);
    if (response) {
      PostData("auth/google-login", { tokenId: response?.tokenId, location: currentLocation }).then(
        (responce) => {
          if (responce?.status === true) {
            Cookies.set("userDetails", responce?.data.user);
            Cookies.set("token", responce?.data?.access_token);
            Cookies.set("userid", responce?.data?.id);
            Cookies.set("userName", responce.data.name);
            setIsLogin(true);
            toast.success(responce.message);
            // Swal.fire("Success", responce.message, "success");
            dispatch(userDetail.userDetails(responce?.data?.user));
            dispatch(actionLoginStatus.loginStatus(true));
            props?.fixLoginToggle()
            setLoading(false);
            navigate("/");
          } else {
            // setLoading(false);
            Swal.fire("Login Failed", response.message, "Login Failed");
          }
        }
      );
    }
  };

  const onFailure = (error) => {
    toast.error(error);
  };


  return (
    <>
      <Modal
        Modal
        className="modal-dialog modal-dialog-centered modal-lg twm-sign-up"
        isOpen={props?.fixLogin}
        toggle={props?.fixLoginToggle}
      >
        <div className="modal-header mt-0 py-0">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props?.fixLoginToggle()
            }}
          ></button>
        </div>
        <ModalBody className="p-0">
          <form onSubmit={handleSubmit(handleLoginSubmit)}>
            <div className="row">
              <div className="col-lg-6 col-12 d-none d-lg-block">
                <div className="login-overlay-img">
                  <img src="./images/loginn.jpeg" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-12 ps-lg-0">
                <div className="twm-tabs-style-2 mt-4 p-3">
                  <h4
                    className="modal-title text-center mb-3"
                    id="login_popupLabel"
                  >
                    Login
                  </h4>
                  <p className="text-center">
                    Login and get access to all the features of Shopspot
                  </p>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-4 position-relative">
                        <input
                          name="username"
                          type="text"
                          required=""
                          className="form-control"
                          placeholder="Email*"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "Incorrect email format",
                            },
                          })}
                        />
                        {errors.email && errors.email.message && (
                          <p
                            className="f-error m-0 fa-circle-xmark"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              position: "absolute",
                              bottom: "-20px",
                              left: "8px",
                            }}
                          >
                            <i className="fa-regular fa-circle-xmark" />
                            {errors.email && errors.email.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-4 position-relative">
                        <input
                          name="password"
                          type="password"
                          className="form-control"
                          required=""
                          placeholder="Password*"
                          {...register("password", {
                            required: "password is required",
                            minLength: {
                              value: 6,
                              message: "Password min length 6 Character",
                            },
                          })}
                        />
                        {errors.password && errors.password.message && (
                          <p
                            className="f-error m-0"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              position: "absolute",
                              bottom: "-20px",
                              left: "8px",
                            }}
                          >
                            <i className="fa-regular fa-circle-xmark" />
                            {errors.password && errors.password.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <div class="form-check">
                          {/* <input
                              type="checkbox"
                              class="form-check-input"
                              id="Password3"
                            /> */}
                          <label
                            class="form-check-label rem-forgot"
                            for="Password3"
                          >
                            <span></span>
                            <Link
                              onClick={() => {
                                props?.fixEmail(true)
                                props?.fixLoginToggle()
                              }}
                            >
                              Forgot Password ?
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex flex-md-row flex-column align-items-center justify-content-between">
                        <button
                          type="submit"
                          className="site-button login-btn py-2"
                          style={{ width: "auto" }}
                          disabled={loading}
                        >
                          {loading == true ? (
                            <span className="spinner-border text-light spinner-border-sm"></span>
                          ) : (
                            "Login"
                          )}
                        </button>
                        <div className="login-button-between my-2 my-md-0">
                          or
                        </div>
                        <GoogleLogin
                          className="google-btn"
                          clientId="1056687895620-on8ip3n5m5j4f68i5jiuhg9iq3s7pace.apps.googleusercontent.com"
                          buttonText="Login"
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                          cookiePolicy={"single_host_origin"}
                          isSignedIn={false}
                        />
                      </div>
                      <div className="mt-3 mb-3">
                        Don't have an account ?
                        <a
                          onClick={() => {
                            props.fixSignUp(true)
                            props?.fixLoginToggle()
                          }}
                          className="twm-backto-login ms-2"
                        >
                          Sign Up
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

    </>
  );
}
