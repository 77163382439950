import { configureStore } from "@reduxjs/toolkit";
import { apiCall, loctionn } from "./Action";
import { businessDetailIdd } from "./Action";
import { loginStatus } from "./Action";
import { countryName } from "./Action";
import { user } from "./Action";
import { accessToken } from "./Action";
import { countryFlag } from "./Action";
const Store = configureStore({
  reducer: {
    loctionn: loctionn.reducer,
    businessDetailId: businessDetailIdd.reducer,
    loginStatus: loginStatus.reducer,
    countryName: countryName.reducer,
    userDetail: user.reducer,
    accessToken: accessToken.reducer,
    countryFlag: countryFlag.reducer,
    apiCall: apiCall.reducer,

  },
});

export default Store;
