import React from 'react'

export default function Loder() {
  return (
    <>
     {/* <!-- LOADING AREA START ===== --> */}
    <div className="loading-area">
      <div className="loading-box"></div>
      <div className="loading-pic">
        <div className="wrapper">
          <div className="cssload-loader"></div>
        </div>
      </div>
    </div>
    {/* <!-- LOADING AREA  END ====== --> */}
    </>
  )
}
