import React, { useEffect } from 'react'
import Header from '../../commen/Header'
import Footer from '../../commen/Footer'
import { useSelector } from 'react-redux';

export default function Vendor() {
    const countryNameRedux = useSelector((state) => state?.countryName?.action);


    return (
        <>
            {countryNameRedux && <Header class={"header-full-width"} />}
            <div className="page-content">
                {/*Banner Start*/}

                <div
                    className="twm-home2-banner-section container-fluid site-bg-gray bg-cover pt-0"
                    style={{ backgroundImage: "url(images/banner/1.jpg)" }}
                >
                    <div className="row">
                        {/*Left Section*/}
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="twm-bnr-left-section pe-0">
                                <div className="twm-bnr-title-large">
                                    Find a <span className="site-text-primary"> better</span>
                                    <br />
                                    <span className="site-text-primary">solution</span> to enhace your
                                    business
                                </div>
                                <div className="twm-bnr-discription">
                                    To build a digital ecosystem where businesses can sell products and
                                    services directly to customers.
                                </div>
                                <div className="twm-upload-file d-flex">
                                    <a type="button" href={'https://apps.apple.com/in/app/shop-spot-app/id6443730951'} target='blank' className="btn col-md-4 col-6 ps-md-0">
                                        <img src="./images/apple-storepicon-768x221.png" alt="" />
                                    </a>
                                    <a type="button" href={"https://play.google.com/store/apps/details?id=com.cygalmall"} target='blank' className="btn col-md-4 col-6 ps-md-0">
                                        <img src="./images/Google-1-768x221.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/*right Section*/}
                        <div className="col-xl-5 col-lg-5 col-md-12 twm-bnr-right-section">
                            <div className="twm-bnr2-right-content">
                                <img src="./images/vendors-img.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/*Banner End*/}
                {/* HOW IT WORK SECTION START */}
                <div className="section-full p-t120 site-bg-white twm-how-it-work-area2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                {/* TITLE START*/}
                                <div className="section-head left wt-small-separator-outer">
                                    <div className="wt-small-separator site-text-primary">
                                        <div>How It Works</div>
                                    </div>
                                    <h2 className="wt-title">Follow our steps we will help you.</h2>
                                </div>
                                <ul className="description-list">
                                    <li>
                                        <i className="feather-check" />
                                        list your product
                                    </li>
                                    <li>
                                        <i className="feather-check" />
                                        target customers
                                    </li>
                                    <li>
                                        <i className="feather-check" />
                                        user-friendly platform
                                    </li>
                                    <li>
                                        <i className="feather-check" />
                                        find what you’re looking for
                                    </li>
                                </ul>
                                {/* TITLE END*/}
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="twm-w-process-steps-2-wrap">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="twm-w-process-steps-2">
                                                <div className="twm-w-pro-top bg-clr-sky-light">
                                                    <span className="twm-large-number text-clr-sky">01</span>
                                                    <h4 className="twm-title mt-0 ms-0">
                                                        Register
                                                        <br />
                                                        Your Account
                                                    </h4>
                                                    <p>
                                                        You need to create an account to find the best and
                                                        preferred Place.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="twm-w-process-steps-2">
                                                <div className="twm-w-pro-top bg-clr-yellow-light">
                                                    <span className="twm-large-number text-clr-yellow">02</span>
                                                    <h4 className="twm-title mt-0 ms-0">
                                                        Search <br />
                                                        Your Place
                                                    </h4>
                                                    <p>
                                                        You need to create an account to find the best and
                                                        preferred Place.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="twm-w-process-steps-2">
                                                <div className="twm-w-pro-top bg-clr-pink-light">
                                                    <span className="twm-large-number text-clr-pink">03</span>
                                                    <h4 className="twm-title mt-0 ms-0">
                                                        Apply <br />
                                                        For Dream Place
                                                    </h4>
                                                    <p>
                                                        You need to create an account to find the best and
                                                        preferred Place.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="twm-w-process-steps-2">
                                                <div className="twm-w-pro-top bg-clr-green-light">
                                                    <span className="twm-large-number text-clr-green">04</span>
                                                    <h4 className="twm-title mt-0 ms-0">
                                                        Upload <br />
                                                        Your Resume
                                                    </h4>
                                                    <p>
                                                        You need to create an account to find the best and
                                                        preferred Place.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="twm-how-it-work-section" />
                    </div>
                </div>
                {/* HOW IT WORK SECTION END */}
                {/* Vendor section background-image start here.. */}
                <div className="vendors-container">
                    <img src="./images/vendor-bg.png" alt="" />
                </div>
                {/* Vendor section background-image end here.. */}
                {/* VENDOR-APP Download START */}
                <div className="section-full p-t120 twm-for-employee-area site-bg-white">
                    <div className="container">
                        <div className="vendor-download-app position-relative">
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <div className="down-app">
                                        <img src="./images/download-app.png" className="w-100" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <h3 className="download-head">
                                        Downlaod
                                        <br className="d-none d-md-block" />
                                        our mobile App
                                    </h3>
                                    <p className="download-para">
                                        There are many variations of passages of Lorem Ipsum available,
                                    </p>
                                </div>
                                <div className="col-lg-4">
                                    <div
                                        className="twm-upload-file d-flex align-items-center flex-column"
                                        style={{ marginTop: 230 }}
                                    >
                                        <a href='https://apps.apple.com/in/app/shop-spot-app/id6443730951' target='blank' className="btn col-md-6 col-6">
                                            <img src="./images/apple-storepicon-768x221.png" alt="" />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.cygalmall" target="blank" className="btn col-md-6 col-6">
                                            <img src="./images/Google-1-768x221.png" alt="" />
                                        </a>
                                        <div className="download-cart">
                                            <div className="cart-img">
                                                <img src="./images/cart.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* VENDOR-APP Download END */}
            </div>

            <Footer />
        </>

    )
}
