import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useForm } from "react-hook-form";
import { GetData, PostData } from "../../ApiHelper/ApiHelper";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import { CountryCodeJson } from "../commen/CountryCodeJson";
import Swal from "sweetalert2";

export default function SignUp(props) {
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({ latitude: null, logitude: null });
  const [countryCode, setCountryCode] = useState(CountryCodeJson);
  const navigate = useNavigate();
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  // const filteredCountryCode = () => {
  //   return countryCode;
  // };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      },
      (error) => {
        console.error("Error getting geolocation:", error);
      }
    );

    // fetch("http://0.tcp.in.ngrok.io:18544/api/v1/auth/countrys", {
    //   method: "get",
    //   headers: new Headers({
    //     "ngrok-skip-browser-warning": "69420",
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => setCountryCode(data.data))
    //   .catch((err) => console.log(err));
  }, []);

  // const handleChange = (event) => {
  //   setCountryVal(event.target.value);
  // };

  const handlerSignUp = (data) => {
    setLoading(true);
    let signUpData = {
      location: location,
      ...data,
    };
    PostData("auth/signUp", signUpData).then((responce) => {
      if (responce.status === true) {
        setLoading(false);
        Swal.fire("Success", responce?.message, "success");
        navigate("/verifyotp", { state: { data: data } });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error !",
          text: `${responce?.data?.message}`,
          icon: "error",
          showConfirmButton: true, // Set this option to false to remove the OK button
        });
      }
    });
  };
  const toggleFunction = () => {
    if (props?.toggle) {
      return props.toggle();
    }
    if (props.toggleSignUp) {
      return props.toggleSignUp();
    }
    if (props.toggleModalSignupMap) {
      return props.toggleModalSignupMap;
    }
  };

  return (
    <>
      <Modal
        Modal
        className="modal-dialog-centered modal-lg twm-sign-up"
        isOpen={props?.fixSignUp}
        toggle={props?.fixSignUpToggle}
      >
        <div class="modal-header mt-0 py-0">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props?.fixSignUpToggle();
            }}
          ></button>
        </div>
        <ModalBody className="p-0">
          <form onSubmit={handleSubmit(handlerSignUp)}>
            <div class="row">
              <div class="col-lg-6 col-12 d-none d-lg-block">
                <div class="login-overlay-img">
                  <img src="./images/signupp.jpeg" alt="" />
                </div>
              </div>
              <div class="col-lg-6 col-12 ps-lg-0">
                <div class="twm-tabs-style-2 mt-4 p-3">
                  <h4 class="modal-title text-center mb-3" id="OTP_popupLabel">
                    Sign Up
                  </h4>
                  <p class="text-center">
                    Sign Up and get access to all the features of Shopspot
                  </p>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-4 position-relative">
                        <input
                          name="phone"
                          type="text"
                          className="form-control"
                          required=""
                          placeholder="Email*"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Incorrect Email format",
                            },
                          })}
                        />
                        {errors.email && errors.email.message && (
                          <p
                            className="f-error m-0"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              position: "absolute",
                              bottom: "-20px",
                              left: "8px",
                            }}
                          >
                            <i className="fa-regular fa-circle-xmark" />
                            {errors.email && errors.email.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-4 position-relative">
                        <input
                          name="username"
                          type="text"
                          required=""
                          className="form-control"
                          placeholder="Usearname*"
                          {...register("name", {
                            required: "Name is required",
                            pattern: {
                              value: /^[a-zA-Z ]{2,30}$/,
                              message: "Please enter a valid  name",
                            },
                          })}
                        />
                        {errors.name && errors.name.message && (
                          <p
                            className="f-error m-0"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              position: "absolute",
                              bottom: "-20px",
                              left: "8px",
                            }}
                          >
                            <i className="fa-regular fa-circle-xmark" />
                            {errors.name && errors.name.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-4 position-relative">
                        <input
                          name="email"
                          type="password"
                          className="form-control"
                          required=""
                          placeholder="Password*"
                          {...register("password", {
                            required: "password is required",
                            minLength: {
                              value: 6,
                              message: "Password min length 6 Character",
                            },
                          })}
                        />
                        {errors.password && errors.password.message && (
                          <p
                            className="f-error m-0"
                            style={{ color: "red", fontSize: 15 }}
                          >
                            <i className="fa-regular fa-circle-xmark" />
                            {errors.password && errors.password.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-4 position-relative">
                        <div className="input-group">
                          {/* <input
                          type="text"
                          maxLength={3}
                          onClick={handleChange}
                        /> */}
                          <span
                            className="input-group-text p-0 border-0"
                            id="basic-addon1"
                          >
                            <select
                              // value={countryVal}
                              // onChange={(e) => setCountryVal(e.target.value)}
                              className="form-control"
                              {...register("country_code", {
                                required: "country_code is required",
                              })}
                            >
                              <option selected disabled value="">
                                +913
                              </option>
                              {countryCode &&
                                countryCode.length > 0 &&
                                countryCode.map((item, key) => (
                                  <option key={key} value={item.dial_code}>
                                    {`${item?.dial_code}`}
                                  </option>
                                ))}
                            </select>
                          </span>
                          <input
                            name="phone"
                            type="text"
                            maxLength={10}
                            className="form-control"
                            placeholder="Phone*"
                            {...register("phone", {
                              required: "phone number is required",
                            })}
                          />
                          {errors.country_code &&
                            errors.country_code.message && (
                              <p
                                className="f-error m-0"
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  position: "absolute",
                                  bottom: "-20px",
                                  left: "8px",
                                }}
                              >
                                <i className="fa-regular fa-circle-xmark" />
                                {errors.country_code &&
                                  errors.country_code.message}
                              </p>
                            )}
                          {errors.phone && errors.phone.message && (
                            <p
                              className="f-error m-0"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                position: "absolute",
                                bottom: "-20px",
                                right: "8px",
                              }}
                            >
                              <i className="fa-regular fa-circle-xmark" />
                              {errors.phone && errors.phone.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-4 position-relative">
                        <input
                          name="date"
                          type="date"
                          className="form-control"
                          required=""
                          placeholder="Date"
                          max="2000-01-01"
                          // max={new Date(, 0, 31)}
                          // initialDate={initialDate}
                          {...register("dob", {
                            required: "This field is required", // Add validation rule(s) here
                          })}
                        />
                        {errors.dob && errors.dob.message && (
                          <p
                            className="f-error m-0"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              position: "absolute",
                              bottom: "-20px",
                              left: "8px",
                            }}
                          >
                            <i className="fa-regular fa-circle-xmark" />
                            {errors.dob && errors.dob.message}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-lg-12">
                      <div className="form-group mb-4 position-relative d-none">
                        <input
                          name="CPR"
                          type="text"
                          className="form-control"
                          required=""
                          placeholder="CPR Number*"
                          {...register("cpr", {
                            required: "CPR number is required",
                          })}
                        />
                        {errors.cpr && errors.cpr.message && (
                          <p
                            className="f-error m-0"
                            style={{ color: "red", fontSize: 15 }}
                          >
                            <i className="fa-regular fa-circle-xmark" />
                            {errors.cpr && errors.cpr.message}
                          </p>
                        )}
                      </div>
                    </div> */}

                    <div className="col-lg-12">
                      <div className="form-group mb-4 position-relative">
                        <input
                          name="Refer"
                          type="text"
                          className="form-control"
                          required=""
                          placeholder="Referral code"
                          {...register("refer_code", {
                            maxLength: {
                              value: 6,
                              message: "refer code max length 6 Character",
                            },
                          })}
                        />
                        {errors.Refer && errors.Refer.message && (
                          <p
                            className="f-error m-0"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              position: "absolute",
                              bottom: "-20px",
                              left: "8px",
                            }}
                          >
                            <i className="fa-regular fa-circle-xmark" />
                            {errors.Refer && errors.Refer.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <button
                        type="submit"
                        className="site-button"
                        disabled={loading}
                      >
                        {loading == true ? (
                          <span className="spinner-border text-light spinner-border-sm"></span>
                        ) : (
                          "Sign up"
                        )}
                      </button>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-3 mt-3">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="agree1"
                            {...register("checkbox", {
                              required: "This field is required", // Add validation rule(s) here
                            })}
                          />

                          <label className="form-check-label" for="agree1">
                            I agree to the
                            <a className="ms-1">Terms and conditions</a>
                          </label>
                          {errors.checkbox && errors.checkbox.message && (
                            <p
                              className="f-error m-0"
                              style={{ color: "red", fontSize: 15 }}
                            >
                              <i className="fa-regular fa-circle-xmark" />
                              {errors.checkbox && errors.checkbox.message}
                            </p>
                          )}
                          <p>
                            Already registered?
                            <Link
                              to={""}
                              className="twm-backto-login ms-1"
                              onClick={() => {
                                props.fixLoginToggle();
                                props?.fixSignUpToggle();
                              }}
                            >
                              Log in here
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
