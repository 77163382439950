import React, { useEffect, useState } from "react";
import Header from "../../commen/Header";
import { GetData, PostData } from "../../../ApiHelper/ApiHelper";
import { useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export default function AllBusiness() {
  const reduxCountryName = useSelector((state) => state?.countryName?.action);
  const [AllBusiness, SetAllbusiness] = useState([]);
  const location = useSelector((state) => state?.loctionn?.action?.location);
  const [classActive, setClassActive] = useState(1);
  const [paginationRange, setPaginationRange] = useState([1, 4]);
  const [selected, setSelected] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [option, setOption] = useState([]);
  const [businessType, setBussinessType] = useState([]);
  const [bussinessTypeSelect, setBusinessTypeSelect] = useState("");
  const [Keyword, setkeyword] = useState("");
  const [typeValueArray, setTypeValueArray] = useState([]);
  const [onlyBusiness, setOnlyBusiness] = useState([]);
  const [callApi, setCallApi] = useState(false);
  let page = 1;

  // useEffect(() => {
  //     function handleScrollEvent() {
  //         if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
  //             console.log(" bottom of  page");
  //             page = +page + 1

  //             console.log(page)
  //             if (location?.latitude && AllBusiness?.totalPages?.lastIndexOf() <= page) {

  //                 console.log(page)

  //                 GetData(`business/get-business?lat=${location?.latitude}&lng=${location?.longitude}&page=${page}`).then((response) => {
  //                     console.log("resss", response)
  //                     let arr1 = onlyBusiness
  //                     if (response.status === true) {

  //                         for (let i = 0; i < response?.data?.business?.rows?.length; i++) {
  //                             arr1.push(response?.data?.business?.rows[i])
  //                         }
  //                         setOnlyBusiness(arr1)
  //                         setCallApi(true)
  //                         // setOnlyBusiness(onlyBusiness.push(element))

  //                         SetAllbusiness(response?.data)
  //                         // setOnlyBusiness(response?.data?.business?.rows)
  //                         setOption(response?.data?.category)
  //                         let arr = []
  //                         response?.data?.business?.rows.forEach(element => {
  //                             arr.push(element?.type)
  //                         });
  //                         let uniqe = [... new Set(arr)]
  //                         setBussinessType(uniqe)

  //                         console.log("resss", response?.data?.business)

  //                     }
  //                 }).catch(console.log("ERROR",))

  //             }
  //         }
  //     }
  //     window.addEventListener('scroll', handleScrollEvent)

  //     return () => {
  //         window.removeEventListener('scroll', handleScrollEvent);
  //     }
  // }, [onlyBusiness])

  // console.log("after apdate ", onlyBusiness)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (location?.latitude) {
      GetData(
        `business/get-business?lat=${location?.latitude}&lng=${location?.longitude}&page=${classActive}`
      )
        .then((response) => {
          if (response.status === true) {
            SetAllbusiness(response.data);
            setOnlyBusiness(response?.data?.business?.rows);
            setOption(response?.data?.category);
            let arr = [];
            response?.data?.business?.rows.forEach((element) => {
              arr.push(element?.type);
            });
            let uniqe = [...new Set(arr)];
            setBussinessType(uniqe);

          }
        })
        .catch(console.log("ERROR"));
    }
  }, [location, classActive]);

  const optionLabels =
    option &&
    option?.length > 0 &&
    option?.map((item) => {
      return { label: item.name, value: item.id };
    });
  let arr = [];
  const handleSelectedChanged = (selected) => {

    setSelected(selected);
    {
      selected?.length > 0 &&
        selected?.map((item) => {
          arr.push(item?.value);
        });
    }
    setTypeValueArray(arr);

  };

  useEffect(() => {
    if (location?.latitude) {
      PostData(
        `business/business-filter?lat=${location?.latitude}&lng=${location?.longitude}&page=${classActive}`,
        { name: Keyword, categories: typeValueArray, type: bussinessTypeSelect }
      ).then((res) => {
        if (res?.status == true) {
          setOnlyBusiness(res?.data?.business?.rows);
          SetAllbusiness(res?.data);
        }
      });
    }
  }, [Keyword, bussinessTypeSelect, selected]);
  const handleFiltterData = (e, type) => {

  };

  return (
    <div>
      {reduxCountryName && <Header />}
      <>
        {/* CONTENT START */}
        <div className="page-content">
          {/* INNER PAGE BANNER */}
          <div
            className="wt-bnr-inr overlay-wraper bg-center"
            style={{ backgroundImage: "url(images/banner/1.jpg)" }}
          >
            <div className="overlay-main site-bg-white opacity-01" />
            <div className="container">
              <div className="wt-bnr-inr-entry">
                <div className="banner-title-outer">
                  <div className="banner-title-name">
                    <h2 className="wt-title">All Business</h2>
                  </div>
                </div>
                {/* BREADCRUMB ROW */}
                <div>
                  <ul className="wt-breadcrumb breadcrumb-style-2">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li>All business</li>
                  </ul>
                </div>
                {/* BREADCRUMB ROW END */}
              </div>
            </div>
          </div>
          {/* INNER PAGE BANNER END */}
          {/* OUR BLOG START */}
          <div className="section-full p-t120  p-b90 site-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-12 rightSidebar">
                  <div className="side-bar">
                    <div className="sidebar-elements search-bx">
                      <form>
                        <div className="form-group mb-4">
                          <h4 className="section-head-small mb-4">Category</h4>
                          <div className="input-group col-xl-4 col-lg-6 col-md-6">
                            <MultiSelect
                              className="wt-search-bar-select selectpicker"
                              options={optionLabels}
                              value={selected}
                              onChange={handleSelectedChanged}
                              labelledBy={"Select Category"}
                              overrideStrings={{
                                selectSomeItems: "Select Category",
                                allItemsAreSelected: "All Category",
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-4">
                          <h4 className="section-head-small mb-4">Keyword</h4>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="bussiness Title or Keyword"
                              onChange={(e) => {
                                setkeyword(e?.target?.value);
                                handleFiltterData(e, "name");
                              }}
                            />
                            <button className="btn" type="button">
                              <i className="feather-search" />
                            </button>
                          </div>
                        </div>
                        <div className="twm-sidebar-ele-filter">
                          <h4 className="section-head-small mb-4">
                            Business Type
                          </h4>
                          <ul>
                            {businessType?.length > 0 &&
                              businessType?.map((item, key) => (
                                <li>
                                  <div className=" form-check" key={key}>
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      id="exampleCheck1"
                                      name="Allbussiness"
                                      value={item}
                                      onChange={async (e) => {
                                        await setBusinessTypeSelect(
                                          e?.target?.value
                                        );
                                        handleFiltterData(e, "type");
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleCheck1"
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </li>
                              ))}
                            <div className=" form-check">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="exampleCheck1"
                                name="Allbussiness"
                                value={""}
                                onChange={(e) => {
                                  setBusinessTypeSelect(e?.target?.value);
                                  handleFiltterData(e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck1"
                              >
                                All
                              </label>
                            </div>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="twm-advertisment"
                    style={{ backgroundImage: "url(images/add-bg.jpg)" }}
                  >
                    <div className="overlay" />
                    <h4 className="twm-title">Vendor?</h4>
                    <p>
                      Take your Busienss to the <br />
                      next leval with us
                    </p>
                    <Link to={"/vendor"} className="site-button white">
                      <i className="feather-briefcase"></i>
                      Become vender
                    </Link>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  {/*Filter Short By*/}
                  <div className="product-filter-wrap d-flex justify-content-between align-items-center m-b30">
                    <span className="woocommerce-result-count-left">
                      Showing {AllBusiness?.business?.count} business
                    </span>
                  </div>
                  <div className="twm-jobs-list-wrap">
                    <ul>
                      {onlyBusiness?.length > 0 &&
                        onlyBusiness?.map((item, key) => (
                          <li key={key}>
                            <div className="twm-jobs-list-style1 mb-5">
                              <div className="twm-media">
                                <img src={item?.business_licence} alt="#" />
                              </div>
                              <div className="twm-mid-content">
                                <Link
                                  to={`${item?.type == "Business"
                                    ? `/businessdetail?id=${item?.id}`
                                    : "/freelancedetail"
                                    }`}
                                  state={{ id: item?.id, name: item?.name }}
                                  className="twm-job-title"
                                >
                                  <h4>
                                    {item?.name}
                                    <span className="twm-job-post-duration">
                                      {/* / {item?.Category?.name} */}
                                    </span>
                                  </h4>
                                </Link>
                                <Link to={'/business'} state={{ id: item?.category?.id }} className="twm-jobs-amount text-primary">
                                  {item?.category?.name}
                                </Link>
                                <p className="twm-job-address text-truncate text-wrap mt-2 d-flex align-items-start">
                                  <span className="me-1">
                                    <i className="feather-map-pin"></i>
                                  </span>
                                  {item?.address}
                                </p>
                              </div>
                              <div className="twm-right-content">
                                <div className="twm-jobs-category green d-block mb-4">
                                  <span className="twm-bg-green">
                                    {item?.type}
                                  </span>
                                </div>

                                <span
                                  // href="job-detail.html"
                                  className="twm-jobs-browse site-text-primary"
                                >
                                  {item?.distance?.toFixed(2)} Km
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="pagination-outer">
                    <div className="pagination-style1">
                      {AllBusiness?.totalPages?.length > 0 && (
                        <ul className="clearfi'x">
                          <li className="prev">
                            <a
                              // className={classActive == 1 ? "d-none" : ""}
                              onClick={() => {
                                if (classActive > 1) {
                                  setClassActive(classActive - 1);
                                }
                                if (paginationRange[0] > 1) {
                                  setPaginationRange([
                                    paginationRange[0] - 1,
                                    paginationRange[1] - 1,
                                  ]);
                                }
                              }}
                            >
                              <span>
                                {" "}
                                <i className="fa fa-angle-left"></i>{" "}
                              </span>
                            </a>
                          </li>
                          {AllBusiness &&
                            AllBusiness?.totalPages?.length > 0 &&
                            AllBusiness?.totalPages
                              .slice(paginationRange[0] - 1, paginationRange[1])
                              .map((item, key) => (
                                <li
                                  key={key}
                                  className={
                                    classActive === item ? "active" : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      setClassActive(item);
                                    }}
                                  >
                                    {item}
                                  </a>
                                </li>
                              ))}
                          <li className="next">
                            <a
                              onClick={() => {
                                if (
                                  classActive < AllBusiness?.totalPages?.length
                                ) {
                                  setClassActive(classActive + 1);
                                  setPaginationRange([
                                    paginationRange[0] + 1,
                                    paginationRange[1] + 1,
                                  ]);
                                }
                              }}
                            >
                              <span>
                                {" "}
                                <i className="fa fa-angle-right"></i>{" "}
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* OUR BLOG END */}
        </div>
        {/* CONTENT END */}
      </>
    </div>
  );
}
