import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import AllRoutes from "./routes/AllRoutes";
import { actionLoction } from "./store/Action";
import { useEffect, useState } from "react";
import { userDetail } from "./store/Action";
import { actionBusinessDetailId } from "./store/Action";
import LatestExperienceDetail from "./Components/Pages/LatestExperience/LatestExperienceDetail";
import CareerList from "./Components/Pages/Career/CareerList";
import CarrerDetail from "./Components/Pages/Career/CarrerDetail";
import { actionCountryName } from "./store/Action";
import Cookies from "js-cookie";
import { actionAccessToken } from "./store/Action";
import { ToastContainer } from "material-react-toastify";
import 'material-react-toastify/dist/ReactToastify.css';
import { actioncountryFlag } from "./store/Action";
import { ModalBody, Modal } from "reactstrap";


function App() {
  const dispatch = useDispatch();
  const loc = useSelector((state) => state?.loctionn?.action?.location);
  const con = useSelector((state) => state?.countryName?.action);
  const reduxCountryFlag = useSelector((state) => state?.countryFlag?.action);
  const [loctionModal, setLocationModal] = useState(false)
  const toggleLoctionModal = () => setLocationModal(!loctionModal)
  const [ErrorLoction, setErrorLoction] = useState('')
  const [CallApi, setCallApi] = useState(false)








  let isLogin = useSelector((state) => {
    return state?.loginStatus.action;
  });
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        localStorage.setItem(
          "loction",
          JSON.stringify({ latitude: latitude, longitude: longitude })
        );
        const location =
          JSON.parse(window.localStorage.getItem("loction")) || {};
        dispatch(actionLoction.loction({ location }));
      },
      (error) => {
        setErrorLoction(error)
        if (error) {
          console.log("error")
          setLocationModal(true)
        } else {
          console.log("seccess")
          setLocationModal(false)
          setCallApi(true)

        }

      }
    );
    if (Cookies.get("userDetails")) {
      dispatch(userDetail.userDetails(Cookies.get("userDetails")));
    }
  }, []);

  useEffect(() => {
    if (con) {
      localStorage.setItem("countryName", JSON.stringify(con));
      Cookies.set("country", con)
    }


    let countryName = "";

    const countryNameString = window.localStorage.getItem("countryName")

    if (countryNameString) {
      try {
        countryName = JSON.parse(countryNameString);
      } catch (error) {
        console.error(error);
      }
    }

    dispatch(actionCountryName?.countryName(countryName));

    // Cookies?.set("flag", reduxCountryFlag)

    const flag = Cookies?.get("flag")

    dispatch(actioncountryFlag?.countryFlag(flag))


    let token = Cookies.get("token")
    dispatch(actionAccessToken?.accessToken(token))

  }, [con, isLogin]);

  return (
    <>
      <AllRoutes />
      <ToastContainer />
      {/* <CarrerDetail /> */}
      {/* <LatestExperienceDetail/> */}
      {/* <h1 className="App-link">
        Learn React jai ganesaye namh ; jai maa gungal ; jai tetees koti devi
        devta ki jai ho
      </h1> */}


      <Modal
        Modal
        className="modal-dialog-centered twm-sign-up"
        isOpen={loctionModal}
        toggle={!ErrorLoction && toggleLoctionModal}
      >
        <ModalBody>
          <form>
            {/* <div class="modal-header mt-0">
              <button
                type="button"
                className="btn-close"
                onClick={handleExitPopUp}
              ></button>
            </div> */}
            <div className="twm-tabs-style-2">
              <div class="mb-3">
                <img class="otp-img" src="./images/error.png" alt="" />
              </div>
              <h4 class="modal-title text-center mb-3" id="OTP_popupLabel">
                Please allow browser location
              </h4>
              <p class="text-center">
                Get more useful information from sites by letting them see your location.   <strong> {''}</strong>
                <br class="d-none d-md-block" />
              </p>
            </div>
          </form>
        </ModalBody>
      </Modal>

    </>
  );
}

export default App;
